<template>
  <div>
    <div>
      <a-form :form="form" :wrapperCol="{xxl:17,xl:18}" :labelCol="{xxl:7,xl:6}" @submit="handleSubmit" >
        <a-row gutter="20">
          <a-col
            :xxl="4"
            :xl="6"
            :lg="8">
            <a-form-item
              label="搜索类型">
              <a-select v-decorator="['searchType', { rules: [{ required: true, message: '请选择搜索类型!'}] }]">
                <a-select-option value="real_name">姓名</a-select-option>
                <a-select-option value="phone">手机号</a-select-option>
                <a-select-option value="nickname">昵称</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xxl="4"
            :xl="6"
            :lg="8">
            <a-form-item
              label="搜索值">
              <a-input v-decorator="['searchValue', { rules: [{ required: true, message: '请输入搜索值!'}] }]"></a-input>
            </a-form-item>
          </a-col>
          <!--          <a-col-->
          <!--            :xxl="5">-->
          <!--            <a-form-item-->
          <!--              label="日期范围">-->
          <!--              <a-range-picker></a-range-picker>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col
            :lg="8">
            <a-form-item>
              <a-button type="primary" icon="search" html-type="submit">搜索</a-button>
              <a-button class="margin-left-16" icon="redo" @click="handleReset">重置</a-button>
              <a-button class="margin-left-16" icon="export" @click="exportData">导出数据</a-button>
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="ant-card">
      <s-table
        ref="table"
        size="middle"
        :data="loadData"
        :columns="columns"
        :scroll="{x:1800}">
        <span slot="name" slot-scope="text, record">
          {{ record.real_name||"-" }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="showUserDetail(record.member_id)">个人明细</a>
        </span>
      </s-table>
    </div>

  </div>
</template>

<script>
import { STable } from '@/components'
import { exportWeightRecords, loadWeightRecords } from '@/api/pc'
import UserDetailInfo from '@/views/user/UserDetailInfo'
export default {
  name: 'WeighRecord',
  components: {
    STable
  },
  data () {
    return {
      form: this.$form.createForm(this),
      columns: [{
        title: '姓名',
        dataIndex: 'real_name',
        align: 'center',
        width: 80,
        fixed: 'left',
        scopedSlots: { customRender: 'name' }
      }, {
         title: '昵称',
          dataIndex: 'nickname',
        width: 160,
        align: 'center',
        fixed: 'left'
      },

        {
          title: '体重(kg)',
          dataIndex: 'weight',
          align: 'center',
          width: 80,
          fixed: 'left'
        },
        {
          title: '年龄',
          dataIndex: 'age',
          align: 'center',
          width: 80
        },
        {
          title: '身高(cm)',
          dataIndex: 'height',
          align: 'center'
        },
        {
          title: 'BMI',
          dataIndex: 'bmi',
          align: 'center'
        },
        {
          title: '体脂率(%)',
          dataIndex: 'bfr',
          align: 'center'
        },
        {
          title: '肌肉率(%)',
          dataIndex: 'rom',
          align: 'center'
        },
        {
          title: '蛋白率(%)',
          dataIndex: 'pp',
          align: 'center'
        },
        {
          title: '基础代谢率(kcal)',
          dataIndex: 'bmr',
          align: 'center'
        },
        {
          title: '内脏脂肪指数',
          dataIndex: 'uvi',
          align: 'center'
        },
        {
          title: '皮下脂肪率(%)',
          dataIndex: 'sfr',
          align: 'center'
        },
        {
          title: '骨骼肌率(%)',
          dataIndex: 'rosm',
          align: 'center'
        },
        {
          title: '称重时间',
          dataIndex: 'create_time',
          align: 'center',
          width: 180,
          fixed: 'right'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 80,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }],
      queryParams: {},
      loadData: parameter => {
        return loadWeightRecords(Object.assign(this.queryParams, parameter)).then(res => {
          return res.result
        })
      }
    }
  },
  methods: {
    showUserDetail (member) {
       this.showModal(UserDetailInfo, '用户明细', 900, 640, { member: member })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.queryParams = values
          this.$refs.table.refresh(true)
        }
      })
    },
    handleReset () {
      this.form.resetFields()
       this.queryParams = {}
       this.$refs.table.refresh(true)
    },
    exportData () {
      exportWeightRecords(this.queryParams).then(res => {
        this.saveResultAsExcel(res, '称重记录.xlsx')
      })
    }
  }
}
</script>

<style scoped>

</style>
